.cdx-legal {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(207, 207, 207);
  min-height: 80px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  border: 4px solid rgba(0, 0, 0, 0.1);
}
.cdx-legal__message {
  outline: none;
  width: 100%;
  margin: 0px;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgb(27, 54, 73);
  font-weight: 600;
}

.cdx-link {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 80px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid;
}

.cdx-link__title {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  font-weight: 600;
}

.cdx-link__message {
  outline: none;
  width: 100%;
}

@media (min-width: 600px) {
  .cdx-link__child1 {
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .cdx-link__child2 {
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .cdx-link__child3 {
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .cdx-link__child1 {
    flex-basis: 75%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 75%;
  }
  .cdx-link__child2 {
    flex-basis: 25%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 25%;
  }
  .cdx-link__child3 {
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.cdx-link__child3 {
  padding-top: 16px;
  border-top: 1px solid;
  margin-top: 20px;
}

.cdx-alertBox {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(255, 141, 58);
  min-height: 80px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  border: 4px solid rgba(0, 0, 0, 0.1);
}

.cdx-alertBox__title {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgb(36, 36, 36);
  font-weight: 600;
}

.cdx-alertBox__message {
  outline: none;
  width: 100%;
  margin: 4px 0px 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(36, 36, 36);
}

.cdx-link__button,
.cdx-link__link {
  text-decoration: none !important;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-transform: none;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(27, 54, 73, 0.5);
  color: #1b3649;
  min-width: 135px;
}

svg.icon--link {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='0.5em' height='0.5em' viewBox='0 0 24 24'%3E%3Cpath fill='black' d='M7.5 17.5q-2.3 0-3.9-1.6T2 12q0-2.3 1.6-3.9t3.9-1.6H18q1.65 0 2.825 1.175T22 10.5q0 1.65-1.175 2.825T18 14.5H8.5q-1.05 0-1.775-.725T6 12q0-1.05.725-1.775T8.5 9.5H18V11H8.5q-.425 0-.713.288T7.5 12q0 .425.288.713T8.5 13H18q1.05 0 1.775-.725T20.5 10.5q0-1.05-.725-1.775T18 8H7.5Q5.85 8 4.675 9.175T3.5 12q0 1.65 1.175 2.825T7.5 16H18v1.5H7.5Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;
}
svg.icon--unlink {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='0.5em' height='0.5em' viewBox='0 0 24 24'%3E%3Cpath fill='blue' d='M7.5 17.5q-2.3 0-3.9-1.6T2 12q0-2.3 1.6-3.9t3.9-1.6H18q1.65 0 2.825 1.175T22 10.5q0 1.65-1.175 2.825T18 14.5H8.5q-1.05 0-1.775-.725T6 12q0-1.05.725-1.775T8.5 9.5H18V11H8.5q-.425 0-.713.288T7.5 12q0 .425.288.713T8.5 13H18q1.05 0 1.775-.725T20.5 10.5q0-1.05-.725-1.775T18 8H7.5Q5.85 8 4.675 9.175T3.5 12q0 1.65 1.175 2.825T7.5 16H18v1.5H7.5Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;
}

/* INPUT VARIABLE CSS */
.cdx-inputBox {
  margin-top: 24px;
  width: 100%;
}
.cdx-inputBox__label {
  font-family: Inter, sans-serif;
  font-size: 0.83rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 8px;
}

.required:after {
  content: " *";
  color: red;
}
.cdx-inputBox__input {
  border: none;
  background-image: none;
  background-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 1px solid #1b3649;
  height: 48px;
  width: 100%;
  padding: 12px;
  margin: 4px 0px 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(36, 36, 36);
}

.cdx-inputBox__input-error {
  border-color: red;
}
.cdx-inputBox__errorLabel {
  color: red !important;
  display: none;
}

.cdx-show {
  display: block;
}

.cdx-hide {
  display: none;
}


.cdx-multiInputBox__input {
  border: none;
  background-image: none;
  background-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 1px solid #1b3649;
  min-height: 50px;
  width: 100%;
  padding: 12px;
  margin: 4px 0px 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(36, 36, 36);
}

.cdx-inputBox__optionContainer {
  border: 1px solid rgb(255, 45, 45);
  padding: 5px;
}

@media print {
  .cdx-inputBox {
    margin: 0px !important;
  }
  .cdx-inputBox__input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 6px;
  }
  .required:after {
    content: "";
  }
}
